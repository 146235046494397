<template>
    <div>
        <form-purchase />
        <MetaTags/>
    </div>
</template>
<script>
import FormPurchase from './components/form-purchase.vue'
export default {
  components: { FormPurchase },
    
}
</script>
<style lang="">
    
</style>